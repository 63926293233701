body {
  margin: 0;
}

.stats {
  position: absolute !important;
  top: unset !important;
  left: unset !important;
  bottom: 0;
  right: 0;
}

fieldset {
  border: none !important;
  outline: none !important;
}
